import React from 'react'
import PropTypes from 'prop-types'
import HeadingStyled from './styled'

const Heading = ({
  level, font, children, ...props
}) => (
  <HeadingStyled {...{ level, font, ...props }}>{children}</HeadingStyled>
)

Heading.displayName = 'Heading'

Heading.propTypes = {
  ...HeadingStyled.propTypes,
  children: PropTypes.node.isRequired,
}

export default Heading
