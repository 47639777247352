import styled from 'styled-components'
import { theme } from 'styled-tools'

const ContainerStyled = styled.div`
  position: relative;
  width: ${theme('container.width')};
  max-width: ${(props) => props.size};
  margin-right: auto;
  margin-left: auto;
`
export default ContainerStyled
