import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../../config/theme'

import ParagraphStyled from './styled'

const Paragraph = ({
  font, size, weight, children, ...props
}) => {
  const fontSize = theme.paragraphSizes[size]
  return (
    <ParagraphStyled
      {...{
        font,
        size: fontSize,
        weight,
        ...props,
      }}
    >
      {children}
    </ParagraphStyled>
  )
}

Paragraph.propTypes = {
  font: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(theme.paragraphSizes)),
  weight: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Paragraph.defaultProps = {
  font: theme.fonts.munkenSans,
  size: 'normal',
  weight: '400',
}

export default Paragraph
