import styled from 'styled-components'
import { palette, theme } from 'styled-tools'

import Heading from '../Heading'
import Paragraph from '../Paragraph'

export const FormModal = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: ${theme('zIndex.hsform')};
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: ${theme('container.width')};
  max-width: 62rem;
  overflow: hidden;
  background-color: ${palette('white')};
  border-radius: 0.75rem;
  box-shadow: ${theme('boxShadows.hsform')};
  transform: translate(-50%, -50%);

  ${theme('media.smUp')} {
    width: 90%;
  }
`

export const FormModalContent = styled.div`
  position: relative;
  padding: 4.75rem 1.25rem 2rem;

  ${theme('media.smUp')} {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 0;
  }
`

export const ButtonStyled = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  padding: 0.875rem;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: unset;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`

export const ImageContainer = styled.aside`
  display: none;

  ${theme('media.smUp')} {
    display: block;
  }
`

export const FormContentContainer = styled.div`
  ${theme('media.smUp')} {
    padding: 7.5rem 4.5rem 2rem;
  }
`

export const FormHeading = styled(Heading)`
  margin-bottom: 1.25rem;
  font-weight: bold;
  font-size: 2rem;
  font-family: ${theme('fonts.libreBaskerville')};
  letter-spacing: 0.8px;

  ${theme('media.smUp')} {
    margin-bottom: 2rem;
  }
`

export const FormDescription = styled(Paragraph)`
  margin-bottom: 1.25rem;
  font-weight: bold;
  line-height: 1.25;

  ${theme('media.smUp')} {
    margin-bottom: 2rem;
  }
`

export const FormContent = styled.div`
  label,
  input[type='email'],
  .hs-error-msgs {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: ${theme('fonts.munkenSans')};
    letter-spacing: 0.35px;
  }

  label {
    display: block;
  }

  input[type='email'] {
    width: 100%;
    padding: 0.625rem;
    border: 1px solid ${palette('black')};

    &:focus {
      color: ${palette('red')};
      border-color: ${palette('red')};
      outline: 0;
    }
  }

  .hs-error-msg {
    color: ${palette('red')};
    font-weight: 400;
  }

  input[type='submit'] {
    width: 100%;
    margin-top: 0.875rem;
    padding: 0.5rem;
    color: ${palette('white')};
    font-weight: 500;
    font-size: 1rem;
    font-family: ${theme('fonts.munkenSans')};
    letter-spacing: 0.2;
    background-color: ${palette('black')};
    border: 0;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    ${theme('media.smUp')} {
      margin-top: 2rem;
    }
  }
`

export const CloseText = styled.button`
  display: block;
  margin: 2rem auto 0;
  font-family: ${theme('fonts.munkenSans')};
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: unset;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
`

export const FancyText = styled.p`
  position: absolute;
  top: 3rem;
  left: 50%;
  z-index: 1710;
  color: ${palette('yellow', 0)};
  font-weight: 700;
  font-size: 10rem;
  font-family: ${theme('fonts.libreBaskerville')};
  transform: rotate(-6deg) translateX(-50%);
`

export const SubmitMessageContainer = styled.div`
  position: relative;
  z-index: 1720;
  padding-top: 10rem;
`

export const SubmitMessageHeader = styled(Paragraph)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.19;
  letter-spacing: 0.8px;

  ${theme('media.smUp')} {
    margin-left: -3rem;
  }
`

export const SubmitMessageText = styled(Paragraph)`
  font-weight: bold;
  line-height: 1.25;

  ${theme('media.smUp')} {
    padding: 2rem 0 6rem;
  }
`
