/* eslint-disable no-param-reassign */
import set from 'lodash.set'
import { getSrc } from 'gatsby-plugin-image'

const mainProducts = {
  MAIN_PRODUCT: 'MainProduct',
  ACCESSORY: 'Accessory',
  CONFIGURATOR: 'Configurator',
}

const accessories = {
  SUBSCRIPTION: 'ACCESSORIES_SUBSCRIPTION',
  INSURANCE: 'Diebstahlschutz',
}

const YEARLY = 'jährlich'

const updateOptions = (product, options, customOption, label) => {
  Object.values(customOption).forEach((value) => {
    if (product.title.includes(value.name)) {
      options.push({
        name: label,
        value: value.name,
      })
    }
  })
}

const getNumericShopifyId = (shopifyId) => shopifyId.replace(/^.*\/(\d+)$/, '$1')

const mergeVariants = (products, config) => {
  const variants = []

  products.forEach((product) => {
    const options = []
    updateOptions(product, options, config.conditions, config.options.condition.name)
    updateOptions(product, options, config.payments, config.options.payment.name)

    product.variants.forEach((variant) => {
      variants.push({
        ...variant,
        selectedOptions: [...variant.selectedOptions, ...options],
      })
    })
  })

  return variants.map((variant) => ({
    ...variant,
    // strip gid://shopify/ProductVariant/ or gid://shopify/Product/
    variantId: getNumericShopifyId(variant.shopifyId),
  }))
}

const getOptionIndex = (options, title) => {
  let index = null
  options.forEach((option, i) => {
    if (option.title === title) {
      index = i
    }
  })
  return index
}

const hasOptionValue = (optionValues, value) =>
  optionValues.map((item) => item.value).includes(value)

const getTranslatedColorValue = (value, colors) => {
  const idx = Object.values(colors).findIndex(
    (item) => item.name.toLowerCase() === value.toLowerCase()
  )

  if (idx !== -1) {
    return Object.keys(colors)[idx]
  }
  return value
}

const getOrder = (options, key = false) => {
  const optionsOrder = {}
  Object.keys(options).forEach((item) => {
    const { name, order } = options[item]
    if (key) optionsOrder[item] = order
    else optionsOrder[name] = order
  })

  return optionsOrder
}

const getOptionsOrder = (title, options) => getOrder(options)[title]

const getType = (options, name) => {
  const colorKeys = Object.keys(options)
    .filter((item) => options[item].isColorOption)
    .map((key) => options[key].name)
  return colorKeys.includes(name) ? 'color' : 'single'
}

const hasCustomOption = (customOrders, slug, key) => Object.keys(customOrders[slug]).includes(key)

const getValueMap = (option, config, slug) => {
  let valueMap
  const hasCustomOrders = config.customOrders && Object.keys(config.customOrders).includes(slug)
  if (option.title === config.options.condition.name) {
    valueMap = getOrder(config.conditions)
  } else if (option.title === config.options.types.name) {
    if (hasCustomOrders && hasCustomOption(config.customOrders, slug, 'types')) {
      valueMap = config.customOrders[slug].types
    }
  } else if (option.title === config.options.frameColor.name) {
    if (hasCustomOrders && hasCustomOption(config.customOrders, slug, 'frameColor')) {
      valueMap = config.customOrders[slug].frameColor
    } else {
      valueMap = getOrder(config.colors, true)
    }
  } else if (option.title === config.options.fabricColor.name) {
    if (hasCustomOrders && hasCustomOption(config.customOrders, slug, 'fabricColor')) {
      valueMap = config.customOrders[slug].fabricColor
    } else {
      valueMap = getOrder(config.colors, true)
    }
  } else if (option.title === config.options.color.name) {
    if (hasCustomOrders && hasCustomOption(config.customOrders, slug, 'color')) {
      valueMap = config.customOrders[slug].color
    } else {
      valueMap = getOrder(config.colors, true)
    }
  } else if (option.title === config.options.payment.name) {
    valueMap = getOrder(config.payments)
  }
  return valueMap && Object.keys(valueMap).reduce((acc, curr) => {
    acc[curr.toLowerCase()] = valueMap[curr]
    return acc
  }, {})
}

const getAllOptions = (variants, config, slug) => {
  const options = []

  variants.forEach((variant) => {
    variant.selectedOptions.forEach((option) => {
      if (option.name === 'Title') {
        return
      }

      let index = getOptionIndex(options, option.name)
      if (index === null) {
        index = options.length
        options.push({
          title: option.name,
          type: getType(config.options, option.name),
          values: [],
        })
      }

      const value = options[index].type === 'color'
        ? getTranslatedColorValue(option.value, config.colors)
        : option.value
      const label = option.value

      if (!hasOptionValue(options[index].values, value)) {
        options[index].values.push({
          value,
          label,
        })
      }
    })
  })

  options
    .sort(
      (a, b) => getOptionsOrder(a.title, config.options) - getOptionsOrder(b.title, config.options)
    )
    .forEach((option) => {
      const valueMap = getValueMap(option, config, slug)
      if (valueMap) {
        option.values.sort((a, b) =>
          valueMap[a.value.toLowerCase()] - valueMap[b.value.toLowerCase()])
      } else {
        option.values.sort((a, b) => a.value - b.value)
      }
    })
  return options
}

const getProductSlug = (title) => title.replace(/^(NEW)?PROD_/, '').toLowerCase() // strip "PROD_" or "NEWPROD_"
const getAccessorySlug = (title) =>
  title
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '') // replace All " " with "-"

const getFullProductPath = (title) => `/produkt/${getProductSlug(title)}`
const getFullAccessoryPath = (title) => `/zubehoer/${getAccessorySlug(title)}`
const getFullProductConfiguratorPath = (title) => `${getFullProductPath(title)}/konfigurator`

/**
 * GREENTOM => Greentom
 * MOUNTAIN-BUGGY => MountainBuggy
 * WOOM-BIKE => WoomBike
 */
const getTemplateName = (slug) =>
  slug
    .split(/(-|_)/)
    .filter((item) => item !== '-' && item !== '_' && item !== '')
    .reduce(
      (acc, curr) => `${acc}${curr.charAt(0).toUpperCase()}${curr.slice(1).toLowerCase()}`,
      ''
    )
/**
 * Greentom => Greentom
 * MountainBuggy => Mountain Buggy
 * WoomBike => Woom Bike
 */
const getCleanTitle = (title) => title.replace(/([A-Z0-9])/g, ' $1').trim()

const getCleanTitleFromShopifyTitle = (title) =>
  getCleanTitle(getTemplateName(getProductSlug(title)))

const getMinPrice = (product) =>
  Math.min(
    ...product.products.map((item) =>
      parseFloat(
        item.title.includes('jährlich')
          ? item.priceRangeV2.minVariantPrice.amount / 12
          : item.priceRangeV2.minVariantPrice.amount
      ).toFixed(2))
  )

const getVariantOptionMap = (options) => {
  if (!options || !options.length) {
    return ''
  }

  const result = {}
  const option = options.shift()

  option.values.forEach((val) => {
    result[val.value] = getVariantOptionMap([...options])
  })

  return result
}

// creates a lookup map, where the final result is the index in the variants array
const getVariantMapByOptionOrder = ({ variants, variantOptions }, config) => {
  const map = getVariantOptionMap([...variantOptions])

  variants.forEach((variant, index) => {
    const selector = variant.selectedOptions
      .sort(
        (a, b) => getOptionsOrder(a.name, config.options) - getOptionsOrder(b.name, config.options)
      )
      .reduce((acc, curr) => `${acc}.${getTranslatedColorValue(curr.value, config.colors)}`, '')
      .substr(1)
    set(map, selector, index)
  })
  return map
}

const isYearly = (variant) =>
  variant
  && (variant.title.includes(YEARLY)
    || variant.selectedOptions.map((option) => option.value).includes(YEARLY))

// get the variant's price
// for yearly we divide by 12
const getVariantPrice = (variant) => {
  let divisor = 1
  if (isYearly(variant)) {
    divisor = 12
  }
  return parseFloat(variant.price) / divisor
}

// is it a special accessory?
const isSpecialAccessory = (product) =>
  product.title.includes(accessories.INSURANCE)
  || product.collections.map((item) => item.title).includes(accessories.SUBSCRIPTION)

const umlautMap = {
  // eslint-disable-next-line quote-props
  '\u00dc': 'UE',
  // eslint-disable-next-line quote-props
  '\u00c4': 'AE',
  // eslint-disable-next-line quote-props
  '\u00d6': 'OE',
  // eslint-disable-next-line quote-props
  '\u00fc': 'ue',
  // eslint-disable-next-line quote-props
  '\u00e4': 'ae',
  // eslint-disable-next-line quote-props
  '\u00f6': 'oe',
  // eslint-disable-next-line quote-props
  '\u00df': 'ss',
}

const replaceUmlauts = (str) =>
  str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)]
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1)
    })
    .replace(new RegExp(`[${Object.keys(umlautMap).join('|')}]`, 'g'), (a) => umlautMap[a])

const variantOptionsCombined = (variants) =>
  variants.reduce((acc, curr) => {
    acc[replaceUmlauts(curr.name)] = replaceUmlauts(curr.value)
    return acc
  }, {})

const sendDataLayer = (name, variant, title) => {
  if (typeof window !== 'undefined') {
    const domain = new URL(window.location.href).hostname
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: name,
      imageUrl: domain + getSrc(variant.image.localFile),
      itemId: variant.variantId,
      title: replaceUmlauts(title),
      url: window.location.href,
      price: variant.price,
      variant: variantOptionsCombined(variant.selectedOptions),
      currency: 'EUR',
    })
  }
}

const getProductInfo = (metafields, key, namespace) => {
  const result = metafields[metafields.findIndex((item) =>
    item.key === key && item.namespace === namespace)]

  return result ? result.value : ''
}

const getProductContent = (metafields) => {
  const filteredObj = metafields.filter((item) => /^details_/.test(item.namespace))

  const reduceObj = filteredObj.reduce((acc, item) => {
    const findItem = acc ? acc.findIndex((s) => s.namespace === item.namespace) : -1
    if (findItem !== -1) {
      acc[findItem][item.key] = item.value
    } else {
      const obj = {
        namespace: item.namespace,
        [item.key]: item.value,
      }
      acc.push(obj)
    }
    return acc
  }, [])

  return reduceObj.sort((a, b) => a.namespace.localeCompare(b.namespace))
}

const getYellowCardInfos = (metafields) => {
  const yellowTitles = metafields.find((m) => m.key === 'yellow_title')
  const yellowText = metafields.find((m) => m.key === 'yellow_text')

  const obj = {
    title: yellowTitles ? yellowTitles.value : '',
    text: yellowText ? yellowText.value : '',
  }

  return obj
}

const getDynamicTheme = (colors) => {
  const palette = Object.keys(colors).reduce((acc, curr) => {
    acc[curr] = [colors[curr].color]
    return acc
  }, {})
  return { palette }
}

export default {
  getProductSlug,
  getTemplateName,
  getCleanTitle,
  getCleanTitleFromShopifyTitle,
  getFullProductPath,
  mergeVariants,
  getVariantMapByOptionOrder,
  getAllOptions,
  getMinPrice,
  getNumericShopifyId,
  isYearly,
  getVariantPrice,
  isSpecialAccessory,
  getFullAccessoryPath,
  getFullProductConfiguratorPath,
  sendDataLayer,
  getProductContent,
  getProductInfo,
  getYellowCardInfos,
  getDynamicTheme,
  mainProducts,
}
