import React, { useState, useEffect } from 'react'
import theme from '../../../../config/theme'

import { ParagraphStyled } from './styled'

const formLabel = 'Jetzt zu unserem Newsletter anmelden'

const EmailForm = () => {
  const [isHidden, setIsHidden] = useState(true)

  const handleConsentExecuted = () => {
    if (window.Cookiebot.consent.marketing) {
      setIsHidden(false)
    }
  }

  useEffect(() => {
    window.addEventListener('CookiebotOnTagsExecuted', handleConsentExecuted)

    return () => {
      window.removeEventListener('CookiebotOnTagsExecuted', handleConsentExecuted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div hidden={isHidden}>
      <ParagraphStyled font={theme.fonts.libreBaskerville}>{formLabel}</ParagraphStyled>
      <div className="klaviyo-form-VpJC2W" />
    </div>
  )
}

export default EmailForm
