import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset'

import theme from '../../config/theme'
import shopify from '../../config/mapping/shopify'

import Navbar, { MainMenu } from '../parts/HeaderNavbar'
import Footer from '../parts/Footer'
import Container from '../parts/Container'
import { PageContainer, FullScreenContainer } from './styled'
import NewsletterOverlay from '../parts/NewsletterOverlay'

const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-family: ${theme.fonts.munkenSans};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 1.25px;
    word-break: break-word;
  }

  .embla__container {
    display: flex;

    > .embla__slide {
      position: relative;
      flex: 0 0 100%;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  input[type=email],
  input[type=submit] {
    border-radius: 0;
    appearance: none;
  }

  .staticPages {
    font-family: ${theme.fonts.munkenSans};
    white-space: pre-line;

    ul {
      list-style: unset;
      list-style-position: inside;
    }
    
    p, ul {
      font-weight: 500;
      font-size: ${theme.paragraphSizes.normal};
      line-height: 1.5;
      letter-spacing: 0.4px;
      
    }

    h1 {
      margin-bottom: 5rem;
      font-size: 3rem;
    }

    h2 {
      font-size: 1.8rem;
      
      ${theme.media.mdUp} {
        font-size: 2rem;
      }
    }

    h3 {
      font-size: ${theme.paragraphSizes.large}; 
    }
  }

  .circuly-relative {
    z-index: 99999 !important;

    & .circuly-drawer {

      &--body {
        & .circuly-basket--main-container {
          padding: 0;
        }

        & .circuly-text-center {
          display: none;
        }

        & .circuly-product-card {
          border: none;
          box-shadow: none;

          &-body {
            padding: 0.25rem 1.25rem;
            border-bottom: 1px solid ${theme.palette.stone[1]};

            & .circuly-h6 {
              font-weight: 400;
              font-size: 1rem;
              letter-spacing: .0125em;
            }

            & .circuly-wrapper {
              margin-left: 0 !important;

              & img {
                width: 5rem;
              }
            }

            & button {
              color: ${theme.palette.black[0]};
            }
          }
          
          &-attributes { display: none; }
        }
      }
      
      &.circuly-outlined {
        padding: 0;
        background-color: ${theme.palette.white[0]};

        & .circly-drawer--header {
          display: grid;
          grid-template-columns: 1fr 3fr;
          padding: 3rem 1.25rem 2rem 1.25rem;
          border-bottom: 1px solid ${theme.palette.stone[1]};
        }

        & button {
          position: relative;
          padding: 0;
          border: none;
          box-shadow: none;
        }
      }

      & .circuly-header {
        &--toggle-holder {
          align-items: flex-start;
          justify-content: flex-start;
        }

        &--container {
          padding: 0;

          &::after {
            display: block;
            font-weight: 700;
            font-size: 1.5rem;
            font-family: ${theme.fonts.libreBaskerville};
            letter-spacing: 0.6px;
            content: 'Dein Warenkorb';
          }

          & span {
            display: none;
          }
        }
      }

      & .circuly-basket--footer {
        padding: 0.5rem 1.25rem;
        background-color: ${theme.palette.white[0]};

        &__row {
          & p:first-child {
            font-weight: 400;
          }
        }

        & button {
          margin-top: 1.5rem;
          padding: 1.5rem 2rem;
          background-color: ${theme.palette.black[1]};
          transition: ${theme.transition.base};

          &:hover {
            background-color: ${theme.palette.green[0]};
          }

          &::after {
            display: block;
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.2px;
            cursor: pointer;
            content: 'Jetzt bestellen';
          }

          & span {
            display: none;
          }
        }
      }
    }
  }

  .heroImg {
    position: absolute !important;
    visibility: hidden;

    ${theme.media.smUp} {
      height: 90vh;
      max-height: 44rem;
      margin-top: 2rem;
      visibility: visible;
    }
  }

  body > #CybotCookiebotDialog {
    box-shadow: #12121226 2px 2px 30px 2px;

    #CybotCookiebotDialogBody {
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-top: 20px;
      padding-right: 10%;
      padding-bottom: 10px;
      padding-left: 10%;
      overflow: hidden;
      vertical-align: top;
    }

    .CybotCookiebotDialogBodyButton {
      z-index: 10;
      display: inline-block;
      min-width: 80px;
      margin-top: 8px;
      margin-right: 0;
      margin-left: 12px;
      padding: 10px 10px 10px;
      font-weight: 600;
      font-size: 9.5pt;
      white-space: nowrap;
      text-align: center;
    }
  }
`

const headerLinks = [
  { name: 'Produkte', target: '/produkte' },
  { name: 'Zubehör', target: '/zubehoer' },
  { name: 'Circular Economy', target: '/circular-economy' },
  { name: 'Über uns', target: '/ueber-uns' },
]

const footerLinks = [
  { target: '/produkte', name: 'Produkte' },
  { target: '/zubehoer', name: 'Zubehör' },
  { target: '/circular-economy', name: 'Circular Economy' },
  { target: '/refurbish-prozess', name: 'Refurbish-Prozess' },
  { target: '/faq', name: 'FAQ' },
  { target: '/ueber-uns', name: 'Über uns' },
  { target: '/impressum', name: 'Impressum' },
  { target: '/datenschutz', name: 'Datenschutz' },
  { target: '/agb', name: 'AGB' },
]

const Root = ({ children, pageContext }) => {
  if (pageContext?.templateName === shopify.mainProducts.CONFIGURATOR) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
        <MainMenu links={[]} display={false} />
      </ThemeProvider>

    )
  }
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PageContainer>
        <FullScreenContainer>
          <Navbar links={headerLinks} />
          <NewsletterOverlay />
          {children}
        </FullScreenContainer>
        <Container>
          <Footer links={footerLinks} />
        </Container>
      </PageContainer>
    </ThemeProvider>
  )
}

Root.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({
    templateName: PropTypes.oneOf(
      Object.values(shopify.mainProducts)
    ),
    isAccessory: PropTypes.bool,
  }),
}

Root.defaultProps = {
  pageContext: null,
}
export default Root
