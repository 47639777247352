import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../../config/theme'

const Headings = ({ level, font, ...rest }) => {
  const hLevel = Math.max(1, Math.min(parseInt(level, 10), 6))
  const Htag = `h${hLevel}`

  return <Htag {...rest} />
}

Headings.propTypes = {
  level: PropTypes.number.isRequired,
  font: PropTypes.oneOf(Object.values(theme.fonts)).isRequired,
}

const HeadingStyled = styled(Headings)`
  font-family: ${({ font }) => font};
`

HeadingStyled.defaultProps = {
  font: theme.fonts.munkenSans,
}

export default HeadingStyled
