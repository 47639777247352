import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'

import theme from '../../../config/theme'

const Container = ({ size, children, ...props }) => {
  const sizeValue = theme.container.sizes[size]

  return <ContainerStyled {...{ size: sizeValue, ...props }}>{children}</ContainerStyled>
}

Container.displayName = 'Container'

Container.propTypes = {
  size: PropTypes.oneOf(Object.keys(theme.container.sizes)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

Container.defaultProps = {
  size: 'default',
}

export default Container
