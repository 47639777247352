const palette = {
  yellow: ['#edd767', '#f2e2a9'],
  green: ['#376268', '#739195'],
  sage: ['#bcd0c7'],
  stone: ['#d0d1d1', '#e5e6e6'],
  sand: ['#efdece', '#fbf8f5'],
  red: ['#ff5f0e', '#fee2d5', '#d0331f', '#f8ece9'],
  blue: ['#164fbb'],
  purple: ['#962ab3'],
  gray: ['#505b56'],
  white: ['#ffffff'],
  black: ['#000000', '#1a2f32'],
  transparent: ['transparent'],
  woomRed: ['#eb0613'],
  woomGreen: ['#279c38'],
  woomPurple: ['#9406ff'],
  orange: ['#ff7a00'],
  mint: ['#86976D'],
  Mint: ['#86976D'],
}

const fonts = {
  munkenSans: `"Munken Sans Web", -system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
  libreBaskerville: `"Libre Baskerville", serif`,
}

const breakpoints = {
  xs: '22.5rem', // 360px
  sm: '43.75rem', // 700px
  md: '60rem', // 960px
  lg: '75rem', // 1200px
  xl: '87.5rem', // 1400px
  tv: '131.25rem', // 2100px
}

const media = {}
Object.keys(breakpoints).forEach((key) => {
  media[`${key}Down`] = `@media (max-width: ${breakpoints[key]})`
  media[`${key}Up`] = `@media (min-width: ${breakpoints[key]})`
})

const container = {
  width: '94%',
  sizes: {
    default: '75rem', // 1200px
    small: '61.25rem', // 980px
  },
}

const transition = {
  base: 'all 0.2s ease-in',
}

const zIndex = {
  behind: -1,
  unset: 0,
  base: 1,
  auto: 'auto',
  modal: 1200,
  mobileMenu: 1600,
  hsform: 1700,
}

const utils = {
  srOnly: {
    border: 0,
    clip: 'rect(1px, 1px, 1px, 1px)',
    'clip-path': 'inset(50%)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
    'white-space': 'nowrap',
  },
}

const paragraphSizes = {
  large: '1.5rem',
  normal: '1rem',
}

const boxShadows = {
  hsform: '30px 30px 50px 0 rgba(0, 0, 0, 0.25)',
}

export default {
  palette,
  fonts,
  breakpoints,
  media,
  container,
  transition,
  zIndex,
  utils,
  paragraphSizes,
  boxShadows,
}
