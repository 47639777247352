import styled from 'styled-components'

import Paragraph from '../../Paragraph'

// eslint-disable-next-line import/prefer-default-export
export const ParagraphStyled = styled(Paragraph)`
  font-size: 1rem;
  font-style: italic;
  line-height: 1.5;
`
