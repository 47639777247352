/* eslint-disable no-underscore-dangle */
import '@fontsource/libre-baskerville/700.css'
import '@fontsource/libre-baskerville/400-italic.css'
import 'react-datepicker/dist/react-datepicker.css'
import './src/static/fonts.css'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'

registerLocale('de', de)
setDefaultLocale('de')
