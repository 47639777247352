// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-circular-economy-js": () => import("./../../../src/pages/circular-economy.js" /* webpackChunkName: "component---src-pages-circular-economy-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-erfolg-js": () => import("./../../../src/pages/erfolg.js" /* webpackChunkName: "component---src-pages-erfolg-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-refurbish-prozess-js": () => import("./../../../src/pages/refurbish-prozess.js" /* webpackChunkName: "component---src-pages-refurbish-prozess-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-zubehoer-js": () => import("./../../../src/pages/zubehoer.js" /* webpackChunkName: "component---src-pages-zubehoer-js" */),
  "component---src-templates-front-js": () => import("./../../../src/templates/Front.js" /* webpackChunkName: "component---src-templates-front-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/Static.js" /* webpackChunkName: "component---src-templates-static-js" */)
}

