import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { theme } from 'styled-tools'

export const NavigationContainer = styled.div`
  display: flex;
`

export const NavigationColumn = styled.div`
  width: 50%;
`

export const NavigationLink = styled((props) => <Link {...props} />)`
  margin-bottom: 0.5rem;
  color: inherit;
  font-weight: normal;
  font-size: 0.875rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
