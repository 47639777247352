/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { NavigationContainer, NavigationColumn, NavigationLink } from './styled'

const Navigation = ({ links }) => {
  const sliceSeparator = Math.ceil(links.length / 2)
  const leftColumnLinks = links.slice(0, sliceSeparator)
  const rightColumnLinks = links.slice(sliceSeparator)

  return (
    <NavigationContainer>
      <NavigationLinks links={leftColumnLinks} />
      <NavigationLinks links={rightColumnLinks} addCookieChoice />
    </NavigationContainer>
  )
}

const UnstyledButton = styled.button`
  display: inline-block;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  color: #000000;
  font-weight: normal;
  font-size: 0.875rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.7;
  text-decoration: none;
  background-color: transparent;
  border: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: 0;
    cursor: pointer;
  }
`

const NavigationLinks = ({ links, addCookieChoice }) => (
  <NavigationColumn>
    {links.map(({ target, name }) => (
      <div key={name}>
        <NavigationLink to={target}>{name}</NavigationLink>
      </div>
    ))}
    {addCookieChoice && (
      <div key="cookie-choice">
        <UnstyledButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.Cookiebot.renew()
            }
          }}
        >
          Cookie-Einstellungen
        </UnstyledButton>
      </div>
    )}
  </NavigationColumn>
)

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  addCookieChoice: PropTypes.bool,
}

Navigation.defaultProps = {
  addCookieChoice: false,
}

NavigationLinks.propTypes = {
  ...Navigation.propTypes,
}

export default Navigation
