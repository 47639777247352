import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { palette, theme } from 'styled-tools'

import Logo from '../../../static/images/logo.svg'
import Cart from '../../../static/images/icons/cart.svg'

export const LogoStyled = styled(Logo)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: block;
  width: ${(props) => (props.scrolled === 'true' ? '6rem' : '7.75rem')};
  transform: translateY(-1rem);
`

export const Nav = styled.nav`
  position: ${(props) => (props.scrolled ? 'fixed' : 'absolute')};
  top: ${(props) => (props.scrolled || !props.isFrontPage ? '0' : '2rem')};
  z-index: 1030;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${(props) => (props.scrolled ? '4rem' : '8rem')};
  margin-top: ${(props) => (props.scrolled ? '0' : '2rem')};
  background-color: ${(props) => (props.scrolled ? 'white' : 'transparent')};
  box-shadow: ${(props) => (props.scrolled ? '0 2px 4px 0 rgba(0, 0, 0, 0.25)' : 'none')};
  & #cartIcon {
    margin-bottom: 1.025rem;

    ${theme('media.smUp')} {
      margin-bottom: 0;
    }

    ${theme('media.mdUp')} {
      margin-left: 2rem;
    }

    & button {
      box-shadow: none;
    }
  }
`
export const NavContainer = styled.div`
  position: relative;
  width: 94%;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
`

export const CartButton = styled.button`
  padding: 0;
  background-color: ${palette('transparent')};
  border: 0;
  outline: 0;
  box-shadow: 'unset';

  &:hover,
  &:focus {
    cursor: pointer;
  }
`
export const CartIcon = styled(Cart)`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
`

export const NavLink = styled((props) => <Link {...props} />)`
  margin-bottom: 1.025rem;
  padding: 0 1rem;
  color: ${palette('black')};
  font-weight: bold;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.4;
  letter-spacing: 0.35px;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid ${palette('transparent')};

  &[aria-current='page'],
  &:hover {
    border-bottom: 1px solid ${palette('black')};
  }

  ${theme('media.smUp')} {
    margin-bottom: 0;
    margin-left: 0.9rem;
    padding: 0 0.45rem;
  }

  ${theme('media.mdUp')} {
    margin-left: 2rem;
    padding: 0 1rem;
  }
`

export const NavItems = styled.div`
  display: none;

  ${theme('media.smUp')} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-left: 7.5rem;
    text-align: center;
  }
`

export const SideMenu = styled.div`
  position: relative;
  float: right;
  /* The rest copied directly from react-burger-menu docs */
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;

    ${theme('media.smUp')} {
      display: none;
    }
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    height: 3px !important;
    background: ${palette('black')};
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    top: 3rem !important;
    right: 1rem !important;
    padding: 0.5rem !important;
  }
  /* Color/shape of close button cross */
  .bm-cross {
    height: 32px !important;
    background: ${palette('black')};
  }
  /* General sidebar styles */
  .bm-menu {
    padding: 7rem 1.5rem 0 1.5rem;
    font-size: 1.15em;
    background: ${palette('white')};
  }
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  ${theme('media.smUp')} {
    display: none;
  }

  .bm-menu-wrap {
    top: 0;
    transform: translate3d(0, 0, 0);
  }
`

export const MenuNav = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 8rem;
  ${theme('media.smUp')} {
    display: none;
  }

  &:focus {
    outline: none;
  }
`
