import React, {
  useState, useContext, useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'

import {
  Nav,
  NavContainer,
  NavLink,
  LogoStyled,
  CartButton,
  CartIcon,
  MenuNav,
  NavItems,
  SideMenu,
} from './styled'

// make a new context
const MenuContext = React.createContext()

// create the provider
const MenuProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  const { children } = props
  return (
    <MenuContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        closeMenu: () => setMenuOpenState(false),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const LogoLink = ({ scrolled }) => {
  const ctx = useContext(MenuContext)
  return (
    <Link to="/" aria-label="Zur Startseite" onClick={ctx.closeMenu}>
      <LogoStyled scrolled={scrolled.toString()} />
    </Link>
  )
}
LogoLink.propTypes = {
  scrolled: PropTypes.bool.isRequired,
}

const NavLinks = ({ links }) => {
  const ctx = useContext(MenuContext)
  return links.map((link) => (
    <NavLink key={link.name} to={link.target} onClick={ctx.closeMenu}>
      {link.name}
    </NavLink>
  ))
}

export const MainMenu = ({ links, display }) => {
  const scriptTag = useRef(null)
  useEffect(() => {
    scriptTag.current.src = '//cdn2.circuly.io/v1/cart.js'
  })

  const style = {
  }

  if (!display) {
    style.display = 'none'
  }

  return (
    <NavItems style={style}>
      <NavLinks links={links} />
      <NavLink to="https://portal.strollme.de" target="_blank" rel="noopener noreferrer">
        Login
      </NavLink>
      <div id="cartIcon" data-embed="circuly-cart-button" persistent="true" />
      <script ref={scriptTag} type="module" defer />
    </NavItems>
  )
}

MainMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })
  ).isRequired,
  display: PropTypes.bool,
}

MainMenu.defaultProps = {
  display: true,
}

const TopNavbar = ({ links }) => {
  const ctx = useContext(MenuContext)
  return (
    <SideMenu>
      <Menu
        right
        noOverlay
        width="100%"
        isOpen={ctx.isMenuOpen}
        onStateChange={(state) => ctx.stateChangeHandler(state)}
      >
        <MenuNav>
          <NavLinks links={links} />
          <NavLink to="https://portal.strollme.de" target="_blank" rel="noopener noreferrer">
            Login
          </NavLink>
          <CartButton data-embed="circuly-open-cart" aria-label="Warenkorb öffnen">
            <CartIcon />
          </CartButton>
        </MenuNav>
      </Menu>
    </SideMenu>
  )
}

TopNavbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })
  ).isRequired,
}

const Navbar = ({ links, isFrontPage }) => {
  const prevScrollY = useRef(0)
  const [scrolled, setScrolled] = useState(false)

  const changeColor = () => {
    const currentScrollY = document.documentElement.scrollTop

    if (prevScrollY.current < currentScrollY || currentScrollY < 69) {
      setScrolled(false)
    }
    if (prevScrollY.current > currentScrollY && currentScrollY > 69) {
      setScrolled(true)
    }

    prevScrollY.current = currentScrollY
  }
  useEffect(() => {
    window.addEventListener('scroll', changeColor)
  }, [])

  return (
    <MenuProvider>
      <Nav aria-label="Hauptnavigation" scrolled={scrolled} isFrontPage={isFrontPage}>
        <NavContainer>
          <LogoLink scrolled={scrolled} />
          <MainMenu links={links} />
          <TopNavbar links={links} className="sticky-top" />
        </NavContainer>
      </Nav>
    </MenuProvider>
  )
}

Navbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })
  ).isRequired,
  isFrontPage: PropTypes.bool,
}

Navbar.defaultProps = {
  isFrontPage: false,
}
export default Navbar
