import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

export const SvgOverlay = styled.div`
  display: none;

  > svg {
    transform: translateX(4rem);
  }

  ${theme('media.lgUp')} {
    position: absolute;
    top: 8rem;
    right: 0;
    z-index: 1;
    display: block;
    width: 24rem;
    overflow: hidden;
  }
`
export const Header = styled.div`
  z-index: 1;
  margin-bottom: ${({ pullUp }) => pullUp && '-15rem'};
  padding-top: 8rem;
  background: ${({ backgroundColor }) => backgroundColor};

  ${theme('media.smUp')} {
    margin-bottom: ${({ pullUp }) => pullUp && '-5rem'};
  }

  & > div {
    padding-bottom: ${({ pullUp }) => pullUp && '10rem'};
    ${theme('media.smUp')} {
      padding-bottom: ${({ pullUp }) => pullUp && '5rem'};
    }
  }
`

export const TitleWrapper = styled.div`
  position: relative;
  padding: 6.5rem 0 4rem 0;

  & h1 {
    font-weight: 700;
    font-size: 2rem;
    font-family: ${theme('fonts.libreBaskerville')};
    line-height: 1.25;
    letter-spacing: 0.8px;

    ${theme('media.smUp')} {
      font-size: 3rem;
      letter-spacing: 1.25px;
    }
  }
`

export const PageContainer = styled.div`
  background-color: ${palette('sand', 1)};
`

export const FullScreenContainer = styled(PageContainer)`
  min-height: 100vh;
`
